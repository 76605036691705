import * as React from "react"
import { Layout } from "../../components/layout"
import { Col, Container, Row } from "react-bootstrap"
import ProductsListing from "../../components/ProductsListing"
import { Seo } from "../../components/seo"
import { graphql } from "gatsby"
import CollectionsList from "../../components/CollectionsList"


export default function Producten({ data }) {

  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <CollectionsList collections={data?.allShopifyCollection?.nodes} />
        {/*<ProductsListing collectionProducts={data?.allShopifyProduct?.group} />*/}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allShopifyCollection {
      nodes {
        ...CollectionCard
      }
    }
  }
`

// export const query = graphql`
//   query ProductsQuery {
//     allShopifyProduct {
//       group(field: collections___id, limit: 6) {
//         fieldValue
//         nodes {
//           ...ProductCard
//         }
//       }
//     }
//   }
// `

export const Head = () => (
  <Seo
    title="Producten"
    description="Ontdek ons uitgebreide gamma zelfgemaakte producten. Plezante potjes barstensvol smaak én met een lekker hoekje af!"
  />
)
