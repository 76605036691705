import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"
import { graphql, Link } from "gatsby"

function getSubtitleValue(product) {
  const subtitleField = product.metafields.find(x => x.key === "subtitle")
  return subtitleField && subtitleField.value;
}

export function getProductTitle(product) {
  const subtitleValue = getSubtitleValue(product);
  if (subtitleValue) {
    return subtitleValue;
  }
  return product.title;
}

export function getProductSubTitle(product) {
  const subtitleValue = getSubtitleValue(product);
  if (subtitleValue) {
    return product.title;
  }
  return undefined;
}

export default function ProductCard({ product }) {
  const image = product.featuredImage && getImage(product.featuredImage.localFile);
  const subtitle = getProductSubTitle(product);
  const title = getProductTitle(product);

  return (
    <Card className="flex-grow-1 d-flex">
      {
        image && <GatsbyImage alt={product.featuredImage.altText || `${product.title} afbeelding`} image={image} />
      }
      <Card.Body className="text-center flex-grow-0 mt-auto">
        <Card.Title as="h3">{title}</Card.Title>
        {
          subtitle && <Card.Title as="h5" className="text-muted">{subtitle}</Card.Title>
        }
        {
          product.variants.length <= 4
            ? product.variants.map(variant => {
                return (
                  <p key={variant.id} className="text-primary fw-bold fs-5 mb-2">
                    {
                      variant.selectedOptions.map((option, index) => {
                        return option.value === "Default Title" ? "" : `${option.value} - `
                      })
                    }
                    €{variant.price}
                  </p>
                )
              })
            : (
              <p className="text-primary fw-bold fs-5 mb-2">
                Meerdere opties: {product.priceRangeV2.minVariantPrice.currencyCode}{product.priceRangeV2.minVariantPrice.amount} - {product.priceRangeV2.maxVariantPrice.currencyCode}{product.priceRangeV2.maxVariantPrice.amount}
              </p>
            )
        }
        <Link to={`/producten/${product.handle}/`} className="btn btn-primary stretched-link text-light mt-2">Details</Link>
      </Card.Body>
    </Card>
  )
}


export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    collections {
      id
      handle
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    id
    handle
    title
    priceRangeV2 {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    variants {
      id
      displayName
      availableForSale
      title
      price
      weight
      weightUnit
      selectedOptions {
        name
        value
      }
    }
    featuredImage {
      id
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1)
        }
      }
      altText
    }
    options {
      id
      name
      position
      values
    }
    metafields {
      key
      value
    }
  }
`
