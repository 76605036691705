import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import CollectionCard from "./CollectionCard"

function getOrderValue(collection, defaultValue = undefined) {
  const orderField = collection.metafields.find(field => field.key === "order");
  const orderValue = orderField && orderField.value ? parseInt(orderField.value) : undefined;
  if (orderValue === undefined || orderValue === null || isNaN(orderValue)) {
    return defaultValue
  }
  return orderValue;
}

export default function CollectionsList({ collections }) {
  const orderedCollections = collections.sort((a, b) => {
    const orderA = getOrderValue(a, 100);
    const orderB = getOrderValue(b, 100);
    return orderA - orderB;
  })

  return (
    <Container className="mt-5">
      <Row className="mb-6">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <Row>
            <Col>
              <h2 className="display-3 fw-bold text-center">Zin in iets lekkers?</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10} xl={8} xxl={6}>
              <p className="text-center">
                Dat vind je vast tussen onze ambachtelijke producten. Die zijn allemaal vrij van geraffineerde suikers, bewaarmiddelen en toegevoegde geur-, kleur- en smaakstoffen.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {
          orderedCollections.map(collection => {
            return (
              <Col key={collection.id} xs={12} sm={6} lg={4} className="mb-4 d-flex align-items-stretch">
                <CollectionCard collection={collection} />
              </Col>
            )
          })
        }
      </Row>
      <Row>
        <Col>
          <p className="text-center small">
            Let erop dat het aanbod lichtjes kan wijzigen naargelang het seizoen.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
