import * as React from "react"
import { Card } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"


export default function CollectionCard({ collection, eager }) {
  const image = getImage(collection.image && collection.image.localFile)

  return (
    <>
      <Card className="flex-grow-1 d-flex justify-content-end" style={{marginTop: !!image ? "60px" : "0px"}}>
        {
          image && (
            <div style={{marginTop: "60px"}}>
              <div className="card-wrapper-elevated ratio ratio1x1">
                <GatsbyImage alt={collection.image.altText || `${collection.title} afbeelding`} image={image} imgClassName="rounded-3" />
              </div>
            </div>
          )
        }
        <Card.Body className="text-center flex-grow-1 d-flex flex-column justify-content-between align-items-center">
          <Card.Title as="h2">{collection.title}</Card.Title>
          <p className="text-start">{collection.description}</p>
          <Link to={`/producten/categorie/${collection.handle}/`} role="button" className="btn btn-primary stretched-link text-light">{`Onze ${collection.title}`}</Link>
        </Card.Body>
      </Card>
    </>
  )
}

export const query = graphql`
  fragment CollectionCard on ShopifyCollection {
    id
    title
    description
    productsCount
    handle
    image {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
      altText
    }
    metafields {
      key
      value
    }
  }
`
