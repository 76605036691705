import * as React from "react"
import { Button, Col, Row } from "react-bootstrap"
import ProductCard from "./ProductCard"
import classnames from "classnames"
import { Link } from "gatsby"

export default function CollectionProductsListing({ collection, products, showAll }) {
  const collectionProducts = products || collection.products;

  return (
    <>
      <Row>
        {
          collectionProducts.length > 0
            ? collectionProducts.map((product, index) => {
                return (
                  <Col key={product.id} xs={12} sm={6} lg={4} className={classnames("mb-4 align-items-stretch", {
                    "d-flex": index < 2 || showAll,
                    "d-none d-sm-flex": !showAll && index >= 2 && index < 4,
                    "d-none d-lg-flex": !showAll && index >= 4,
                  })}>
                    <ProductCard product={product} />
                  </Col>
                )
              })
            : (
              <div className="d-flex flex-column align-items-center">
                <p className="text-center">
                  We zijn deze producten nog aan het toevoegen op onze website. Kijk later zeker nog eens terug.
                </p>
                <Link to="/producten/" className="btn btn-primary text-light">Andere producten</Link>
              </div>
            )
        }
      </Row>
    </>
  )
}
